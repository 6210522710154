<template>
  <el-dialog
    :model-value="visible"
    title="用户协议"
    :width="600"
    @close="cloneDialog"
  >
    <template v-if="service && service.userAgreement">
      <div
        class="info"
        v-html="service.userAgreement"
      />
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, watch } from 'vue'
import { shopAgreement } from '@/api/system'
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  setup (props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)

    const service = ref(null)
    const getShopAgreement = async () => {
      service.value = await shopAgreement()
    }
    const { modelValue } = toRefs(props)

    // 弹窗是否要打开监控
    watch(modelValue, (val) => {
      visible.value = val
    })
    onMounted(() => {
      getShopAgreement()
    })

    const cloneDialog = () => {
      visible.value = false
      emit('update:modelValue', false)
    }

    return {
      visible,
      loading,
      cloneDialog,
      service
    }
  }
})
</script>
<style lang="scss" scoped>
.info {
  overflow: auto;
  background-color: #fff;
  width: 100%;
  max-height: 461px;
}
</style>
